import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [

  //****TITULO DE GRUPO******* */
  // {
  //   path: '',
  //   title: 'MENUITEMS.MAIN.TEXT',
  //   iconType: '',
  //   icon: '',
  //   class: '',
  //   groupTitle: true,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['All'],
  //   submenu: [],
  // },

  {
    path: '',
    title: 'MENUITEMS.ASSOCIATES.TEXT',
    iconType: 'material-icons-outlined',
    icon: 'face',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/asociados/gestion',
        title: 'MENUITEMS.ASSOCIATES.LIST.GESTION',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/asociados/acceso-web',
        title: 'MENUITEMS.ASSOCIATES.LIST.ACCESO-WEB',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },

  {
    path: '',
    title: 'MENUITEMS.COMMUNICATION.TEXT',
    iconType: 'material-icons-outlined',
    icon: 'email',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/comunicacion/sendMail',
        title: 'MENUITEMS.COMMUNICATION.LIST.EMAILS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/comunicacion/plantillas',
        title: 'MENUITEMS.COMMUNICATION.LIST.TEMPLATES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },

  {
    path: '/documentacion',
    title: 'MENUITEMS.DOCU.TEXT',
    iconType: 'material-icons-outlined',
    icon: 'description',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },

  {
    path: '/listados',
    title: 'MENUITEMS.LISTADOS.TEXT',
    iconType: 'material-icons-outlined',
    icon: 'view_list',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },


  {
    path: '/usuarios',
    title: 'MENUITEMS.USER.TEXT',
    iconType: 'material-icons-outlined',
    icon: 'people_alt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },

   {
    path: '/reclamaciones',
    title: 'MENUITEMS.RECLAMACION.TEXT',
    iconType: 'material-icons-outlined',
    icon: 'email',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },

];
